<template>
    <div>
        <h3>Error 404: Page Not Found</h3>
        <p>Please check that you have entered the correct url and try again.</p>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    mounted () {
        document.title = 'Page Not Found'
    }
}
</script>

<style scoped>
div{
    text-align: center;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #eee, -1px -1px 1px #eee;
}
</style>