<template>
    <div class="row">
          <router-link to="/sign-up">
            <button class="sign-up">Register</button>
          </router-link>
          
          <router-link to="/sign-in"> 
            <button class="sign-in">Login</button>
          </router-link>
    </div>
</template>

<script>
export default {
    name: 'OrsulOnboard'
}
</script>

<style scoped>
.row{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin: 50px auto;
    padding: 30px 60px;
    box-shadow: 1px 1px 2px grey;
    border-radius: 15px;
    text-align:center;
}

button{
    width: 75%;
    margin: 10px auto;
    padding: 10px;
    outline: none;
    border:none;
    color: #fff;
}

button.sign-up{
    background: #570897;
}

button.sign-up:hover{
    background: #3d036d;
}

button.sign-in{
    background: #ffb508;
}

button.sign-in:hover{
    background: #eda601;
}

a{
    width: 50%;
    text-decoration: none;
    color: #fff;
}

@media (max-width: 768px) {
    div.row{
        width: 90%;
    }

    button{
        width: 95%;
    }
}

@media (min-width: 768px) and (max-width: 992px){
    div.row{
        width: 50%;
    }

    button{
        width: 75%;
    }
}
</style>