<template>
  <div class="bg">
      <div class="purple">
            <OrsulHeader background="transparent" linkColor="white"/>
            <OrsulJumbotron />
      </div>
  </div>
</template>

<script>
import OrsulHeader from '@/components/OrsulHeader.vue'
import OrsulJumbotron from '@/components/OrsulJumbotron.vue'

export default {
    name: 'OrsulBG',
    components: {
        OrsulHeader, OrsulJumbotron
    }
}
</script>

<style scoped>
div.bg{
    background-image: url('../assets/orsul_bg8.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/* comment this out to remove the purple overlay */
.purple{
    width:100%;
    margin: 0;
    height: 100%;
    background-color: rgb(50, 6, 84, 0.3);
}
</style>