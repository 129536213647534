<template>
    <OrsulHeader linkColor="purple"/>
    <OrsulHow/>    
    <OrsulFooter />
</template>

<script>
import OrsulHeader from '@/components/OrsulHeader.vue'
import OrsulHow from '@/components/OrsulHow.vue'
import OrsulFooter from '@/components/OrsulFooter.vue'

export default {
    name: 'HowItWorks',
    components:{
        OrsulHeader, OrsulHow, OrsulFooter
    },
    mounted () {
        document.title = 'How Orsul Works'
    }
}
</script>

<style scoped>
*{
  box-sizing: border-box;
}
</style>