<template>
    <section class="services">
      <article>
        <h2>Our services include:</h2>
        <p>
          Services by our Artisans include General tasks, Electrical Work, Plumbing, Tailoring, 
          Carpentry, Painting, Mechanics, Fittings, Delivery. 
          Click the button to below to sign up or log in to the platform.
        </p>
        <br>
        <router-link to="/get-started">
            <button class="get-started">Get Started</button>
          </router-link>
      </article>
    </section>
</template>

<script>
export default {
    name: 'OrsulServices'
}
</script>

<style scoped>
.services{
  width: 80%;
  margin: 80px auto;
  border: 1px solid #ccc;
  padding: 20px 50px;
  border-radius: 15px;
  text-align:center;
}

.services:hover{
  border: 1px solid #eda601;
}

button{
    outline: none;
    border: 1px solid #eda601;
    border-radius: 5px;
    padding: 10px 15px;
    color: #fff;
    background: #eda601;
}

button:hover{
    background: #fab002;
}

a{
    width: 50%;
    text-decoration: none;
    color: #fff;
}

@media (max-width: 768px){
  .services{
    width: 85%;
    padding: 15px;
  }
}
</style>