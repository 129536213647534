<template>
    <router-view/>
</template>

<script>
export default({
  name: 'App',
  components: {  },
  data () {
    return{

    }
  },
})
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body{
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Poppins', sans-serif;
}
</style>

