<template>
    <OrsulRegister/>
</template>

<script>
import OrsulRegister from '@/components/OrsulRegister.vue'

export default ({
  name: 'SignUp',
  components: {
    OrsulRegister
  },
  mounted () {
      document.title = 'Orsul - Register'
  }
})
</script>

<style scoped>
*{
  box-sizing: border-box;
}
</style>

