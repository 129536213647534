<template>
  <OrsulBG />
  <OrsulCoreValues />
  <OrsulServiceCards/>
  <OrsulProfileCards/>
  <OrsulNewsletter/>
  <OrsulFooter />
</template>

<script>
import OrsulBG from '@/components/OrsulBG.vue'
import OrsulCoreValues from '@/components/OrsulCoreValues.vue'
import OrsulServiceCards from '@/components/OrsulServiceCards.vue'
import OrsulProfileCards from '@/components/OrsulProfileCards.vue'
import OrsulNewsletter from '@/components/OrsulNewsletter.vue'
import OrsulFooter from '@/components/OrsulFooter.vue'

export default {
  name: 'Home',
  components: {
    OrsulBG,  OrsulCoreValues, OrsulServiceCards, OrsulProfileCards,  OrsulNewsletter, OrsulFooter
  },
  mounted () {
        document.title = 'Orsul Services Home'
  }
}
</script>

<style scoped>
*{
  box-sizing: border-box;
}
</style>