<template>
    <section>
        <div class="row">
            <div class="myflex">
                <h3 class="flex-title">Fair Pricing</h3>
                <br>
                <p class="flex-text">
                    We've done the work on your behalf to figure out what price is fair per task.
                    All you need to do is relax, and let the pros handle your tasks.
                </p>
            </div>

            <div class="myflex">
                <h3 class="flex-title">Value</h3>
                <br>
                <p class="flex-text">
                    Get real value for your money and put Money back in your pocket. 
                    Our fair pricing system ensures that you don't get charged based on your status or location.
                </p>
            </div>

            <div class="myflex mid-screen-last">
                <h3 class="flex-title">Reduce Waste</h3>
                <br>
                <p class="flex-text">
                    We help you save money,and other resources and improve your productivity. 
                    You also get to keep your money for other good things in life.
                </p>
            </div>
        </div>

        <section class="new-row">
            <div class="image">
                <img src="@/assets/mansmiling_big.jpg" alt="photo" class="photo">
            </div>
            <article class="text">
                <h1>Any issues with getting quality services? Come to ORSUL, Let's fix it together! </h1>
                <p>
                    We are a Nigerian based startup providing solutions to the issue of poor 
                    service delivery by Artisans. We ensure that our clients get quality service and value for their
                    money by connecting them with vetted, verified and trusted 
                    professionals within their location in real time without any unnecessary costs.
                </p>
                <p>
                    Our Platform uses a fair pricing system to ensure that clients do not get extra charges. 
                    Using Orsul services, you can rest assured of credibility, professionalism and excellence.  
                    Your safety and security of personal properties are guaranteed as well.
                </p>
                <span>You can sign up for free and get started right away!</span>
                <br>
                <router-link to="/get-started">
                    <button class="btn-get-started">Get Started</button>
                </router-link>
            </article>
        </section>

        <div class="row">
            <div class="myflex">
                <h3 class="flex-title">Credibility</h3>
                <p class="flex-text">
                    Our bespoke frameworks operates across all verticals of the informal sector. 
                    Hence, we provide nothing short of an excellent service experience. 
                    With Orsul, you can cross your legs and have your work done by real professionals.
                </p>
            </div>
            

            <div class="myflex">
                <h3 class="flex-title">Safety</h3>
                <p class="flex-text">
                    Who wouldn't be worried about strangers coming into their homes? 
                    We take the safety of our clients and partners seriously. 
                    All checks are in place and we are in continuous iteration with security and safety experts.
                </p>
            </div>

            <div class="myflex mid-screen-last">
                <h3 class="flex-title">Price Discovery</h3>
                <p class="flex-text">
                    Up until Orsul, there's no platform you'd go to verify the quotes you 
                    get from your artisans or to ascertain the quality of materials used.
                    Well, we are happy to meet you too!
                </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'OrsulCoreValues',
}
</script>

<style scoped>
h3.core-text{
    margin-top: 30px;
    margin-bottom: 40px;
    text-align:center;
    font-size: 2rem;
    font-weight: 600;
    color:#320654;
    text-transform:capitalize;
}

.row{
    width:75%;
    margin: 30px auto 50px;
    padding: 10px;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
}

.new-row{
    width: 100%;
    margin: 30px 0 50px;
    display: flex;
    justify-content: stretch;
    align-items: center;
    background: linear-gradient(to bottom, #F1F0EB, #E9E5DC, #E4D1B0);
}

.myflex{
    width: 30%;
    height: 220px;
    padding: 10px 20px;
    text-align: center;
    box-shadow:0px 4px 4px #ccc;
    border: none, 1px solid #ccc;
    border-radius: 10px;
    margin-right: 2%;
}

.myflex:hover{
    border: 1px solid #320654;
    transform:scale(1.02);
}

.image{
    width: 40%;
    padding: 0;
} 

.text{
    width: 60%;
    padding: 30px 50px;
}

.photo{
    width: 100%;
    max-height: 500px; 
}

.text h1{
    color: #EDA601;
}

.flex-title{
    color: #320654;
    font-size: 1.1rem;
    margin-bottom: -10px;
}

.flex-text{
    color: #222;
    font-weight: 500;
    font-size: 0.9rem;
}

.btn-get-started{
    margin-top: 15px;
    outline:none;
    padding: 10px 15px; 
    color: #EDA601;
    background:transparent;
    border: 1px solid #EDA601;
    border-radius: 7px;
}

.btn-get-started:hover{
    background: #fab002;
    color: white;
}

a{
    width: 50%;
    text-decoration: none;
    color: #fff;
}


@media (max-width: 768px){
    .row{
        width:90%;
        flex-direction: column;
        justify-content: space-between;
    }

    .myflex{
        width: 75%;
        height: auto;
        padding: 15px 20px ;
        margin: 10px auto 20px;
    }

    .new-row{
        width: 100%;
        flex-direction: column;
        justify-content: stretch;
    }

    .image, .text{
        width: 95%;
        margin: auto;
    }

    .text{
        padding: 20px;
    }

    .text h1{
        font-size: 1rem;
    }

    .text p, .text span{
        font-size: 0.9rem;
    }

    .flex-title{
        font-size: 1rem;
        margin-bottom: -10px;
    }

    .flex-text{
        font-size: 0.9rem;
    }

    h3.core-text{
        font-size: 1.5rem;
        margin-bottom: -10px;
    }
}

@media (min-width: 769px) and (max-width: 992px){
    .row{
        width:75%;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .new-row{
        width: 100%;
        flex-direction: row;
        justify-content: stretch;
    }

    .myflex{
        width: 43%;
        margin: 20px 1%;
        height: 230px;
        padding: 15px;
    }

    .my-flex:last-child, .mid-screen-last{
        Width: 43%;
        margin: auto;
    }

    .photo{
        max-height: 100%;
    }
}
</style>