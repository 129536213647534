<template>
    <div class="newsletter">
        <h1>Join our Newsletter</h1>
        <p>Subscribe to our newsletter to get the latest updates on our services!</p>
        <input type="email" placeholder="Your email address.." required autofocus><br>
        <button type="submit">Subscribe</button>
    </div>
</template>

<script>
export default {
    name: 'OrsulNewsletter'
}
</script>

<style scoped>
.newsletter{
    width: 75%;
    margin: 50px auto;
    background: #570897;
    color:#fff;
    text-align: center;
    padding: 15px;
    border-radius: 20px;
}

input{
    width: 50%;
    height: 35px;
    background-color: #fff;
    color: #1F0533;
    padding: 5px 25px;
    border-radius:20px;
    border:none;
    outline:none;
}

button{
    text-align:center;
    padding: 10px 20px;
    margin-top: 15px;
    border-radius: 20px;
    border: none;
    outline: none;
    color:#fff;
    background-color: #EDA601;
}

button:hover{
    transform: scale(1.1);
    box-shadow: 0px 4px 8px rgb(25, 42, 146, 0.12)
}

@media (max-width: 768px){
    .newsletter{
        width: 85%;
    }

    input{
        width: 80%;
    }
}
</style>