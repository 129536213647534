<template>
    <div class="how">
        <article>
            <h1>Here's how Orsul works:</h1>
            <p>
                Sign up as an home/office owner, and get started immediately. A few clicks here and there gets your
                connected to professionals in the field of any task you went to get done.
                Artisans can register with the Orsul platform and start their verification 
                process almost immediately as well.
            </p>
            <p>For more information, reach out to us anytime!</p>
            <a href="mailto:orsul@gmail.com">
                <button>Contact Orsul</button>
            </a>
        </article>
    </div>
</template>

<script>
export default {
    name: 'OrsulHow'

}
</script>

<style scoped>
*{
    box-sizing: border-box;
}

.how{
    width: 80%;
    margin: 80px auto;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 15px;
    text-align:center;
}

.how:hover{
    box-shadow: 1px 1px 1px #ccc, -1px -1px 1px #ccc;
}

button{
    outline: none;
    border: 1px solid #eda601;
    border-radius: 5px;
    padding: 10px 15px;
    color: #fff;
    background: #eda601;
}

button:hover{
    color: #eda601;
    background: #fff;
}

a{
    width: 50%;
    text-decoration: none;
    color: #fff;
}

@media (max-width: 768px){
  .how{
    width: 90%;
    padding: 15px;
  }
}
</style>