<template>
    <section>
        <div class="container">
            <div class="first-div">
                <div class="first-div-items">
                    <h1>ORSUL</h1>
                    <h3>WELCOME ON BOARD</h3>
                    <div class="buttons">
                        <router-link to="/sign-in"><button class="sign-in">SIGN IN</button></router-link>
                        <router-link to="/get-started"><button class="go-back">GO BACK</button></router-link>
                    </div>
                </div>
            </div>

            <div class="second-div">
                <div class="second-div-items">
                    <h2>Register</h2>
                    <div>
                        <a href="#"><img src="../assets/google-logo.png" alt="" class="social-icon"></a>                    
                        <a href="#"><img src="../assets/facebook-icon.png" alt="" class="social-icon"></a>
                    </div>
                    <div>
                        <p class="grey"> or <br><br> Create an account with your Email</p>
                    </div>
                    <form action="#">
                        <div class="input-field">
                            <i class="bi bi-person"></i>
                            <input v-model="newUser.fullName" type="text" 
                            id="name" name="name"  placeholder="Full Name" 
                            autofocus required 
                            @keypress="checkName($event)"
                            />
                        </div>

                        <div class="input-field">
                            <i class="bi bi-envelope"></i>
                            <input v-model="newUser.email" type="email" 
                            id="email" name="email"  placeholder="Email" 
                            autofocus required 
                            @keypress="checkEmail"
                            />
                        </div>

                        <div class="input-field">
                            <i class="bi bi-lock"></i>
                            <input v-model="newUser.password" type="password" 
                            id="password" name="password" placeholder="Password" 
                            autofocus required 
                            />
                            <i :class=" isActive ? 'bi-eye' : 'bi-eye-slash' " class="bi" id="togglePassword" @click="togglePassword"></i>
                        </div>
                    </form>

                    <div class="btn">
                        <router-link to="/user-dashboard"><button class="sign-up" type="submit" @submit="createAccount">CREATE ACCOUNT</button></router-link>
                    </div>

                    <div class="agreement">
                        <p class="grey">
                            By Creating an account you agree with Orsul.com <br>
                            <a href="#" class="orsul-link">Terms & Condition</a> and <a href="#" class="orsul-link">Privacy Policy.</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'LogIn',
    data(){
        return{
            newUser:{
                fullName: '',
                email: '',
                password: ''
            },
            isActive: true
        }
    },
    methods:{
        togglePassword(){
            const toggle = document.querySelector('#togglePassword');
            const password = document.getElementById('password');
            toggle.addEventListener('click', function(e) {
                const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
                password.setAttribute('type', type);
                this.isActive = !this.isActive;
            });       
        },
        checkEmail(){
            var email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return email.test(this.user.email);
        },
        checkName($event) {
            if (($event.charCode > 64 && $event.charCode < 91) || ($event.charCode > 96 && $event.charCode < 123) || ($event.charCode == 32)) {
                return true
            } else {
                $event.preventDefault();
            }
        },
        // createUser(){
        //     console.log(this.newUser.email)
        // }
    }
        
}

</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css");

*{
    text-decoration: none;
    box-sizing: border-box;
    font-family:Arial, Helvetica, sans-serif;
}

section{
    width: 100%;
    margin: 0;
    padding: 0;
}

div.container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
}

div.first-div{
    width: 35%;
    height: 100%;
    margin: 0;
    padding: 50px;
    background: #570897;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.first-div-items{
    text-align: center;
}

button.sign-in, button.go-back{
    width: 65%;
    margin: 15px auto;
    padding: 12px;
    color: #fff;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 20px;
    outline: none;
    text-align: center;
}

button.sign-in:hover, button.go-back:hover, button.sign-in:active{
    color: #570897;
    background: #fff;
}

div.second-div{
    width: 65%;
    height: 100%;
    margin: 0;
    padding: 50px 100px;
    display:flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #570897;
}

div.second-div-items{
    width: 80%;
    margin: auto;
    text-align: center;
}

h2{
    font-size: 1.2rem;
    font-weight: 700;
    color: #570897;
}

p.grey{
    text-align: center;
    color: #bbb;
    font-weight: 500;
}

form{
    width: 90%;
    margin: 30px auto 15px;
    display:flex;
    flex-direction: column;
    justify-content: spacebetween;
    align-items: center;
}

div.input-field{
    width: 100%;
    margin: auto;
}

input{
    width: 95%;
    margin: 0 auto 20px;
    color: #570897;
    background: #f8f0fe;
    padding: 10px;
    padding-left: 35px;
    border: none;
    border-radius: 2px;
    outline: none;
    z-index: 10;
}

button.sign-up{
    width: 50%;
    margin: 20px auto;
    padding: 10px;
    color: #fff;
    background: #570897;
    border: 1px solid #fff;
    border-radius: 20px;
    outline: none;
    text-align: center;
}

button.sign-up:hover{
    transform: scale(1.1);
}

button.sign-up:active{
    color: #570897;
    background: transparent;
    border: 1px solid #570897;
}

a.orsul-link{
    color: #570897;
}

a.orsul-link:hover{
    border-bottom: 1px thin #570897;
}

.agreement{
    width: 90%;
    margin: auto;
    padding: 15px;
    text-align: center;
    font-size: 13px;
}

.justify{
    text-align: justify !important;
}

.btn{
    width: 90%;
    margin: 15px auto 5px;
    text-align: center;
}

.bi-eye, .bi-eye-slash{
    padding: 10px;
    position: absolute;
    z-index: 15;
    color: #a3a3a3;
    font-weight: 500;
    margin-left: -40px;
    cursor: pointer;
}

.bi-envelope, .bi-lock, .bi-person{
    padding: 10px;
    position: absolute;
    z-index: 15;
    color: #570897;
    font-weight: 500;
    cursor: pointer;
}

.social-icon{
    max-width: 40px;
    height: 40px;
    margin: 15px 20px;
}

.buttons{
    width: 90%;
    margin: auto;
}

@media (min-width: 769px) and (max-width: 1024px){
    div.first-div{
        width: 40%;
        padding: 35px;
    }

    div.second-div{
        width: 60%;
    }

    div.second-div-items{
        width: 90%;
    }

    form{
        width: 95%;
    }

    button.sign-in, button.sign-up, button.go-back{
        width: 65%;
    }
}


@media (max-width: 768px) {
    div.container{
        height: 100%;
        flex-direction: column;
    }

    div.first-div{
        width: 100%;
        height: 75vh;
        padding: 35px;
        order: 2;
    }

    div.second-div{
        width: 100%;
        padding: 35px;
        order: 1;
    }

    div.second-div-items, form{
        width: 95%;
    }

    input{
        width: 100%;
        padding: 12px;
        padding-left: 35px;
    }
    
    button.sign-in, button.sign-up, button.go-back{
        width: 80%;
        margin: 10px auto;
    }

    .btn{
        margin: 30px auto;
    }

    .agreement{
        width: 100%;
        padding: 15px;
    }
}
</style>