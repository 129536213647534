<template>
  <OrsulUserPages />
</template>

<script>
import OrsulUserPages from '@/components/OrsulUserPages.vue'

export default {
  name: 'UserProfile',
  components: {
    OrsulUserPages
  },
  mounted () {
        document.title = 'My Profile'
  }
}
</script>

<style scoped>
*{
  box-sizing: border-box;
}
</style>