<template>
    <OrsulHeader linkColor="purple"/>
    <OrsulOnboard/>
    <OrsulFooter />
</template>

<script>
import OrsulHeader from '@/components/OrsulHeader.vue'
import OrsulOnboard from '@/components/OrsulOnboard'
import OrsulFooter from '@/components/OrsulFooter.vue'

export default {
    name: 'GetStarted',
    components: {
        OrsulHeader, OrsulOnboard, OrsulFooter
    },
    mounted () {
        document.title = 'Get Started with Orsul'
    }
}
</script>

<style>
*{
    box-sizing: border-box;
}
</style>