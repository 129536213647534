<template>
    <section>
        <div class="container">
            <div class="first-div">
                <div class="first-div-items">
                    <h1>ORSUL</h1>
                    <h3>WELCOME BACK</h3>
                    <div class="buttons">
                        <router-link to="/sign-up"><button class="sign-up">CREATE ACCOUNT</button></router-link>
                        <router-link to="/get-started"><button class="go-back">GO BACK</button></router-link>
                    </div>
                </div>
            </div>

            <div class="second-div">
                <div class="second-div-items">
                    <h2>Log In</h2>

                    <form action="#">
                        <div class="input-field">
                            <i class="bi bi-envelope"></i>
                            <input v-model="user.email" type="email"
                            id="email" name="email"  placeholder="Email" 
                            autofocus required
                            @keypress="checkEmail"
                            />
                        </div>

                        <div class="input-field">
                            <i class="bi bi-lock"></i>
                            <input v-model="user.password" type="password" 
                            id="password" name="password" placeholder="Password" 
                            autofocus required 
                            />
                            <i :class=" isActive ? 'bi-eye-slash' : 'bi-eye' " class="bi" id="togglePassword" @click="togglePassword"></i>
                        </div>

                        <div class="forgot-password-field">
                            <span class="forgot-password"> <router-link to="/forgot-password">Forgot Password?</router-link></span>
                        </div>

                        <div class="btn">
                        <router-link to="/user-dashboard"><button class="sign-in" type="submit" @submit="logIn">SIGN IN</button></router-link>
                    </div>
                    </form>

                    <div class="agreement">
                        <p class="grey">
                            By logging into your account you agree with Orsul.com <br>
                            <a href="#" class="orsul-link">Terms & Conditions</a> and <a href="#" class="orsul-link">Privacy Policy.</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {
    name: 'LogIn',
    data(){
        return{
            user:{
                email: '',
                password: ''
            },
            isActive: true
        }
    },
    methods:{
        togglePassword(){
            const toggle = document.querySelector('#togglePassword');
            const password = document.getElementById('password');
            const type = password.getAttribute('type') === 'password' ? 'text' : 'password';

            password.setAttribute('type', type);
            this.isActive = !this.isActive;
        },
        checkEmail(){
            var email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return email.test(this.user.email);
        }
        // logIn(){
        //     console.log(this.newUser.email)
        // }
    }
        
}

</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css");

*{
    text-decoration: none;
    box-sizing: border-box;
    font-family:Arial, Helvetica, sans-serif;
}

section{
    width: 100%;
    margin: 0;
    padding: 0;
}

div.container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
}

div.first-div{
    width: 35%;
    height: 100%;
    margin: 0;
    padding: 50px;
    background: #570897;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.first-div-items{
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
}

button.sign-up, button.go-back{
    width: 75%;
    margin: 15px auto;
    padding: 12px;
    color: #fff;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 20px;
    outline: none;
    text-align: center;
}

button.sign-up:hover, button.go-back:hover, button.sign-up:active{
    color: #570897;
    background: #fff;
}

div.second-div{
    width: 65%;
    height: 100%;
    margin: 0;
    padding: 100px;
    display:flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #570897;
}

div.second-div-items{
    width: 80%;
    margin: auto;
    text-align: center;
}

h2{
    font-size: 1.2rem;
    font-weight: 700;
    color: #570897;
}

form{
    width: 90%;
    margin: 30px auto 10px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

div.input-field{
    width: 100%;
    margin: auto;
}

input{
    width: 95%;
    margin: 0 auto 20px;
    color: #570897;
    background: #f8f0fe;
    padding: 10px;
    padding-left: 35px;
    border: none;
    border-radius: 2px;
    outline: none;
    z-index: 10;
}

div.forgot-password-field{
    width: 100%;
    margin-left: -8%;
    display: flex;
    justify-content: flex-end;
    align-content: center;
}

span.forgot-password a{
    align-self: flex-end;
    margin-top: -10px;
    font-size: 13px;
    font-weight: 700;
    color: #eda601;
}

span:hover{
    color: #dd9d07;
    text-decoration: underline;
}

button.sign-in{
    width: 50%;
    margin: 10px auto;
    padding: 10px;
    color: #fff;
    background: #570897;
    border: 1px solid #fff;
    border-radius: 20px;
    outline: none;
    text-align: center;
}

button.sign-in:hover{
    transform: scale(1.1);
}

button.sign-in:active{
    color: #570897;
    background: transparent;
    border: 1px solid #570897;
}

.agreement{
    width: 90%;
    margin: auto;
    padding: 20px;
    text-align: center;
    font-size: 13px;
}

a.orsul-link{
    color: #570897;
}

a.orsul-link:hover{
    border-bottom: 1px thin #570897;
}

.btn{
    width: 90%;
    margin: 15px auto;
    text-align: center;
}

.bi-eye, .bi-eye-slash{
    padding: 10px;
    position: absolute;
    z-index: 15;
    color: #a3a3a3;
    font-weight: 500;
    margin-left: -40px;
    cursor: pointer;
}

.bi-envelope, .bi-lock{
    padding: 10px;
    position: absolute;
    z-index: 15;
    color: #570897;
    font-weight: 500;
    cursor: pointer;
}

@media (min-width: 769px) and (max-width: 1024px){
    div.first-div{
        width: 40%;
        padding: 35px;
    }

    div.second-div{
        width: 60%;
    }

    div.second-div-items{
        width: 90%;
    }

    form{
        width: 95%;
    }

    button.sign-up, button.sign-in, button.go-back{
        width: 65%;
    }
}


@media (max-width: 768px) {
    div.container{
        height: 100%;
        flex-direction: column;
    }

    div.first-div{
        width: 100%;
        height: 75vh;
        padding: 35px;
        order: 2;
    }

    div.second-div{
        width: 100%;
        padding: 35px;
        order: 1;
    }

    div.second-div-items, form{
        width: 95%;
    }

    input{
        width: 100%;
        padding: 12px;
        padding-left: 35px;
    }
    
    button.sign-in, button.sign-up, button.go-back{
        width: 80%;
        margin: 10px auto;
    }

    .btn{
        margin: 30px auto;
    }

    .agreement{
        width: 100%;
        padding: 15px;
    }
}
</style>