<template>
    <footer>
        <div class="myfooter">
            <div class="foot orsul-text">
                <h2 translate="no">Orsul</h2>
                <p>
                    We are committed to being the link between hardworking Nigerians and artisans, and
                    building a solid platform where clients meet service providers who are diligent, trustworthy and  professional.  
                </p>
            </div>

            <div class="foot">
                <h4>Contact Us</h4>
                <p class="resize"><i class="bi bi-telephone call"></i><a class="call" href="tel:07046462020">+234(0)7046462020</a></p>
                <p class="resize"><i class="bi bi-envelope-fill"><a class="mail" href="mailto:orsul@gmail.com">orsul2020@gmail.com</a></i></p>
                <p class="resize"><i class="bi bi-twitter"></i><a class="social" href="www.twitter.com/orsul">@orsul</a> </p>
                <p class="resize"><i class="bi bi-linkedin"></i><a class="social" href="www.linkedin.com/orsul">@Orsul</a></p>
            </div>

            <div class="foot">
                <h4>About Us</h4>
                <p class="resize">Terms & Conditions</p>
                <p class="resize">Privacy Policy</p>
                <h4>Help Center</h4>
                <p class="resize">Make Enquiries</p>
                <p class="resize">Report a service</p>
            </div>
        </div>
        <div class="copyright">
            <p>Copyright &copy;{{ currentYear() }}.  <a href="https://www.orsul.com" target="blank" class="orsullink">www.orsul.com</a>  All rights reserved.</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'OrsulFooter',
    data () {
        return{}
    },
    methods: {
        currentYear() {
            const date = new Date();
            const year = `${date.getFullYear()}`;
            return year;
        }
    }
}
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css");
*{
    box-sizing: border-box;
}

footer{
    width: 100%;
    margin: 0;
}

.myfooter{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 0;
    padding: 15px 20px;
    color: white;
    background-color: #570897;
}

.foot{
    width: 32%;
    text-align: center;
}

.orsul-text{
    padding: 0 15px;
}

h2{
    translate: no;
}

a.orsullink{
    color: #eda601;
    text-decoration: none;
}

a.orsullink:hover{
    color:#d39402;
    text-decoration: underline;
}

.call{
    color: #37BF21;
    text-decoration: none;
}

.call:hover{
    color: #30a71d;
    text-decoration: underline;
}

.mail, .social{
    color: #fff;
    text-decoration: none;
}

i{
    margin-right: 5px;
}

.copyright{
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 5px 20px;
    color: #fff;
    background-color: #0E0317;
}

footer h5{
    font-weight: 500;
}

footer p{
    font-size: 0.9rem;
}

.resize:hover{
    transform: scale(1.05);
    cursor:pointer
}

@media (max-width: 768px){
    .myfooter{
        flex-direction: column;
        height: 100%;
        padding: 5px;
    }

    .foot{
        width: 85%;
        margin: auto;
        padding: 3px;
        text-align: left;
    }
}

@media(min-width: 768px) and (max-width: 992px){
    .myfooter{
        padding: 20px 30px;
    }
}
</style>