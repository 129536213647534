<template>
    <div class="myjumbotron">
        <div class="info-box">
            <h3>Need help with anything? Relax, your solution is a few clicks away.</h3>
            <p class="p-text">
                From the simplest of tasks to the most herculean ones 
                and everything else, we've got you covered!
                Get started by using the search box below!
            </p>

            <form action="#" class="search-box">
                <i class="bi bi-search"></i>
                <input 
                v-model="user.search" type="search" 
                inputmode="text" ref="search" 
                autofocus required placeholder="What services do you need?"
                @keypress="checkInput($event)" />
                <button class="search" type="button" @click="showPopup">Search</button>
            </form>
            <div class="no-input-alert" ref="noInputAlert">
                <span>You haven't typed anything in the search box!</span>
            </div>

            <div class="backdrop" ref="backdrop">
                <div id="popup" ref="popup">
                    <i @click="hidePopup" class="bi bi-x-circle"></i>
                    <p> Kindly input your name and email address below.</p>
                    <form action="POST" class="myform" @submit.prevent="handleSubmit">
                        <input 
                        v-model="user.name" type="text" 
                        inputmode="text" autofocus required 
                        placeholder="e.g Chukwu Femi Adamu" 
                        ref="inputName"
                        @keypress="checkName($event)" 
                        />

                        <input 
                        v-model="user.phone" type="tel" 
                        inputmode="numeric" autofocus required 
                        placeholder="e.g 09012345678" 
                        ref="inputPhone" maxlength="11" pattern="[0]{1}[7-9]{1}[0-1]{1}[0-9]{8}"
                        @keypress="checkPhone($event)" 
                        />
                        <div class="error-message" ref="errorMessagePhone">
                            <span>This phone number cannot be accepted!</span>
                        </div>

                        <input 
                        v-model="user.email" 
                        type="email" inputmode="email" 
                        autofocus  required placeholder="e.g example@domain.com" 
                        ref="inputEmail" 
                        @keypress="checkEmail"
                        />
                        <div class="error-message" ref="errorMessageEmail">
                            <span>This email address cannot be accepted!</span>
                        </div>

                        <div class="no-input-alert" ref="noInputsAlert">
                            <span> Kindly fill the form as appropriate before submitting!</span>
                        </div>

                        <i class="bi bi-arrow-clockwise spinner" ref="spinner"></i>

                        <button class="submit" type="submit" ref="submit">Submit</button>
                    </form>
                </div>

                <div ref="newContent" id="new-content">
                    <i @click="hideNewContent" class="bi bi-x-circle"></i>
                    <p>
                    Sorry, this service is  unavailable at the moment, be sure
                    that we are working assiduously to vet, verify and onboard as 
                    many artisans as possible within the shortest time.
                    <br/> You will be notified as soon as we do, thank you!
                    </p>
                </div>
            </div>

            <!-- <div class="options">
                <select name="services" id="services">
                    <option value="All Categories" v-for="service in services" :key="service.name"> {{service.name}} </option>
                </select>
            </div> -->
        </div>
    </div>
</template>

<script>
import OrsulHeader from '@/components/OrsulHeader.vue'

export default {
    name: 'OrsulJumbotron',
    components: {
        OrsulHeader
    },
    data () {
        return{
            // services: [
            //     {name: 'All Categories'},
            //     {name: 'General Tools'},
            //     {name: 'Electrical Work'},
            //     {name: 'Plumbing'},
            //     {name: 'Tailoring'},
            //     {name: 'Carpentry'},
            //     {name: 'Painting'},
            //     {name: 'Mechanics'},
            //     {name: 'Fittings'},
            //     {name: 'Delivery'}
            // ],
            noInputAlert: false,
            user:{
                search : "",
                name : "",
                phone: "",
                email : ""
            }
        }
    },
    methods:{
        showPopup(){
            if (this.$refs.search.value != false){
                this.$refs.backdrop.style.display = "flex";
                this.$refs.popup.style.display = "block"
            } else{
                this.$refs.noInputAlert.style.visibility = "visible";
                var self = this;
                setTimeout( function(){
                    self.$refs.noInputAlert.style.visibility = "hidden";
                }, 1000);              
            }        
        },
        hidePopup() {
            this.$refs.backdrop.style.display = "none";
            this.user.search = "";
            this.user.email = "";
            this.user.name = "";
            this.user.phone = "";
        },
        checkInput($event) {
            if (($event.charCode > 64 && $event.charCode < 91) || ($event.charCode > 96 && $event.charCode < 123) || ($event.charCode == 32)) {
                return true
            } else {
                $event.preventDefault();
            }
        },
        handleSubmit(){
            if (this.$refs.inputEmail.value != false && this.$refs.inputName.value != false && this.$refs.inputPhone != false && this.checkEmail != false && this.$refs.inputEmail.checkValidity()){
                var submit = this.$refs.submit;
                var spinner = this.$refs.spinner;
                submit.disabled = true;
                spinner.style.display = "block";

                var popup = this.$refs.popup;
                var newContent = this.$refs.newContent;
                var alert = this.$refs.noInputsAlert;

                var search = this.user.search;
                var name = this.user.name;
                var phone = this.user.phone;
                var email = this.user.email;
                const api_key = process.env.VUE_APP_API_KEY;

                var errorMessageEmail= this.$refs.errorMessageEmail;
                var errorMessagePhone = this.$refs.errorMessagePhone;

                var http = new XMLHttpRequest();
                http.open('POST',api_key, true);
                http.setRequestHeader('Content-Type', 'application/json');
                http.onreadystatechange = function(){
                    if (this.readyState === 4 && this.status === 201){
                        var response = JSON.parse(this.response);

                        popup.style.display = "none";
                        newContent.style.display = "block";

                        submit.disabled = false;
                        spinner.style.display = "none";
                    }else if (this.readyState === 4 && this.status === 400){
                        var response = JSON.parse(this.response);
                        alert.style.visibility = "hidden";

                        if (response.phone){
                            errorMessagePhone.innerHTML = response.phone;
                            errorMessagePhone.style.display = "inline-block";
                        }else{
                            errorMessagePhone.style.display = "none";
                        }
                        
                        if (response.email){
                            errorMessageEmail.innerHTML = response.email;
                            errorMessageEmail.style.display = "inline-block";
                        }else{
                            errorMessageEmail.style.display = "none";
                        }
                        
                        setTimeout( function(){
                            errorMessageEmail.style.display = "none";
                            errorMessagePhone.style.display = "none";
                        }, 2000);

                        submit.disabled = false;
                        spinner.style.display = "none";
                    }else{
                        alert.innerHTML = 'Unable to submit form, please try again';
                        alert.style.visibility = "visible";

                        setTimeout( function(){
                            alert.style.visibility = "hidden";
                        }, 2000);

                        submit.disabled = false;
                        spinner.style.display = "none";
                    };
                };
                var data = JSON.stringify({
                    "search": search,
                    "name": name,
                    "phone": phone,
                    "email": email
                });
                http.send(data);
                

            }else{
                this.$refs.noInputsAlert.style.visibility = "visible";
                var self = this;
                setTimeout( function(){
                    self.$refs.noInputsAlert.style.visibility = "hidden";
                }, 1500)
            }
        },
        hideNewContent() {
            this.$refs.backdrop.style.display = "none";
            this.$refs.newContent.style.display = "none";
            this.user.search = "";
            this.user.email = "";
            this.user.name = "";
            this.user.phone = "";
        },
        checkEmail(){
            var email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return email.test(this.user.email);
        },
        checkName($event) {
            if (($event.charCode > 64 && $event.charCode < 91) || ($event.charCode > 96 && $event.charCode < 123) || ($event.charCode == 32)) {
                return true
            } else {
                $event.preventDefault();
            }
        },
        checkPhone($event) {
            if ($event.charCode > 47 && $event.charCode < 58) {
                return true
            } else {
                $event.preventDefault();
            }
        }
    },
}
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css");

*{
    box-sizing:border-box;
}

body{
    margin:0;
    padding: 0;
}

.myjumbotron{
    width:100%;
    margin: 0;
    height: 75vh;
    background-color: transparent ;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.info-box{
    width: 50%;
    margin: auto;
    padding: 35px;
    background: #fff;
    /* background: #ffffff10;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); */
    border-radius: 5px;
    position: relative;
}

h3, p{
    text-align:center; 
}

.info-box h3{
    font-size: 1.7rem;
    color: #4C0E7A;
    padding: 2px 30px;
    font-weight:700;
}

.info-box p{
    padding: 5px 40px;
    color: #222;
    font-size: 0.9rem;
}

form.search-box{
    width: 80%;
    margin: auto;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

input{
    color:#555;
    width: 80%;
    padding: 12px 5px;
    padding-left: 30px;
    outline:none;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    caret-color: #570897;
    position: relative;
}

input:focus{
    color: #4C0E7A;
    outline: #EDA601;
}

.bi-search{
    padding: 10px;
    position: absolute;
    z-index: 5;
    color: #570897;
    font-weight: 500;
    cursor: pointer;
}

button.search{
    width: 18%;
    padding: 12px 15px;
    color: #fff;
    background-color: #EDA601;
    outline:none;
    border:none;
    border-radius: 3px;
    text-align:center;
}

button.search:hover{
    background: #d89a08;
}

div.backdrop {
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100vh;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 300;
}

#popup{
    width: 50%;
    margin: auto;
    padding: 15px 10px;
    box-shadow: 1px 1px 2px 1px grey;
    background: #fff;
    border-radius: 5px;
}

#popup p{
    padding: 5px;
}

#new-content{
    display: none;
    width: 50%;
    margin: auto;
    padding: 10px;
    box-shadow: 1px 1px 2px 1px grey;
    background: #fff;
    border-radius: 5px;
}

form.myform{
    width: 90%;
    margin: auto;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

form.myform input{
    color: #570897;
    width:70%;
    margin: auto;
    margin-bottom: 10px;
    padding: 10px 15px;
}

button.submit{
    width: 40%;
    padding: 7px 10px;
    color: #fff;
    background-color: #37BF21;
    outline:none;
    border:none;
    border-radius: 3px;
}

button.submit:hover{
    background-color: #2fa31d;
}

button.submit:disabled, button.submit[disabled]{
    background-color: #d5f8d5;
    color: #b3daa0;
}

.bi-x-circle{
    color: red;
    position: relative;
    top: 10px;
    left: 10px;
}

.bi-x-circle:hover{
    color: white;
    background: red;
    padding: 3px;
    border-radius: 10%;
}

div.options{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    margin: 10px auto;
}

select { 
    border: 1px solid #eda601;
    border-radius: 5px;
    outline: none;
    padding: 5px 10px;
}

.no-input-alert{
    width:80%;
    visibility: hidden;
    margin: -10px auto 4px;
    text-align: center;
    font-size: 0.7rem;
    font-weight: 500;
    color: red;
}

.error-message{
    display: none;
    margin: -10px auto 4px;
    font-size: 0.7rem;
    font-weight: 500;
    color: red;
}

.spinner{
    display: none;
    height: 30px;
    width: 30px;
    color: #570897;
    margin-bottom: 6px;
    animation: spinner 0.7s linear infinite;
}

.bi-arrow-clockwise{
    color: #570897;
}

@keyframes spinner{
    to{
        transform: rotate(360deg);
    }
}

@media (max-width:768px){
    .myjumbotron{
        height: 70vh;
    }

    div.info-box{
        width: 75%;
        padding: 5px;
        margin: 30px auto;
    }

    .info-box h3{
        font-size: 1.6rem;
        padding: 5px 20px;
    }

    .info-box p{
        padding: 5px 10px;
    }

    form.search-box{
        width: 85%;
        flex-direction: column;
    }

    input{
        width: 100%;
        margin-bottom: 10px;
    }

    .bi-search{
        margin-right: 67%;
    }

    button.search{
        width: 40%;
        margin: auto;
        padding: 7px 12px;
    }

    #popup, #new-content{
        width: 80%;
    }

    #new-content{
        padding: 5px;
    }

    form.myform input{
        width: 95%;
    }

    .p-text{
        display: none;
    }

    .no-input-alert{
        padding: 7px;
    }

}

@media (min-width:769px) and (max-width:992px){
    .myjumbotron{
        height: 75vh;
    }

    div.info-box{
        width: 60%;
        padding: 15px;
    }

    .info-box h3, .info-box p{
        padding: 5px 15px;
    }

    form.search-box{
        width: 90%;
    }

    form.search-box input{
        width: 78%;
    }

    button.search{
        width: 20%;
    }
} 

</style>

