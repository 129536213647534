<template>
    <OrsulLogin/>
</template>

<script>
import OrsulLogin from '@/components/OrsulLogin.vue'

export default ({
  name: 'SignIn',
  components: {
    OrsulLogin
  },
  mounted () {
        document.title = 'Orsul - Log In'
  }
})
</script>

<style scoped>
*{
  box-sizing: border-box;
}
</style>

