<template>
    <section id="featured-artisans">
        <h2>Featured Artisans</h2>
        <div class="row">
            <div v-for="profile in profiles" :key="profile.id" class="profile">
                <div class="first">
                    <div class="image-div">
                        <img :src="require(`@/assets/${profile.img}`)" :alt="profile.name" class="image" loading="lazy">
                    </div>
                    
                    <div class="info-div">
                        <p class="name"> {{ profile.name }} </p>
                        <p class="class"> <i class="bi bi-award-fill"></i> {{ profile.class}} </p>
                        <p class="hrate"><i class="bi bi-cash"></i> {{ profile.hrate }} </p>
                        <p class="ratings"><i class="bi bi-star-fill"></i>{{ profile.ratings }}</p>
                        <p class="tasks"><i class="bi bi-check-circle-fill"></i>{{ profile.tasks }} Jobs completed</p>
                    </div>
                </div>
                <hr/>

                <div class="second">
                    <p class="about-tag">About</p>
                    <p class="about-note"> {{ profile.about }} </p>
                </div>
                <hr/>

                <div class="third">
                    <h5>Top Skills:</h5>
                    <p class="skill"> {{ profile.skills[0] }} </p>
                    <p class="skill"> {{ profile.skills[1] }} </p>
                    <p class="skill"> {{ profile.skills[2] }} </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'OrsulProfileCards',
    data (){
        return{
            profiles: [
                {
                    id: 1, name: 'Ola Hassan', ratings: 'Excellent', tasks: '91', img: 'olahassan.jpg', class: 'Rookie', hrate: 'N1500/hr' ,
                    about: 'Ola is a skillful Plumber with over 5 years of experience fixing simple and complex issues with home and office plumbing systems. ',
                    skills: ["Plumbing" , "Fittings" , "Delivery" ] 
                },
                {
                    id: 2, name: 'John Daniel', ratings: 'Fair', tasks: '74', img: 'johndoe.jpg', class: 'Rookie', hrate: 'N1500/hr' ,
                    about: 'John is a middle aged Carpenter and Furniture maker who has amazing work ethics and a creative mind to bring your new furniture idea to life or fix issues with existing ones.  ',
                    skills: ["Carpentry" , "Furniture Work" , "Moving" ] 
                },
                {
                    id: 3, name: 'Sarah Smith', ratings: 'Good', tasks: '134', img:'sarahsmith.jpg', class: 'Amateur', hrate: 'N2500/hr' ,
                    about: 'Sarah is an experienced auto mechanic and car repairer who does great works with electrical appliances as well.  ', 
                    skills: ["Mechanics" , "Car Repair" , "Electrical Work" ] 
                },
            ]
        }
    }
}
</script>

<style scoped>
*{
    box-sizing:border-box;
}

section{
    width: 75%;
    margin: auto;
    padding: 0;
}

h2{
    color: #222;
    margin-left: 10px;
    margin-bottom: -20px;
}

div.row{
    width:100%;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.profile{
    width: 31%;
    height: 400px;
    margin-right: 1%;
    padding: 20px 15px;
    box-shadow: 1px 1px 5px 1px rgb(189, 183, 183);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

.profile:hover{
    box-shadow: 2px 2px 5px 2px rgb(189, 183, 183);
}

.first, .second, .third{
    width: 100%;
    margin-bottom: 8px;
}

hr{
    width: 90%;
    margin: auto;
    height: 1px;
    border: thin solid #ddd;
    background: #ddd;
}

.first{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.image-div{
    width: 35%;
    margin-right: 5%;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
}

.image{
    width: 100%;
    max-height: 90px;
    border-radius: 5px;
}

.info-div{
    width: 60%;
}

.name{
    font-size: 0.95rem;
    color: #222;
    font-weight: 600;
    margin-bottom: -8px;
    margin-top: -5px;
}

.ratings, .tasks, .class, .hrate{
    font-size: 0.8rem;
    color: #222;
    margin-bottom: -8px;
}

.bi-star-fill{
    color: #570897;
    margin-right: 5px;
}

.bi-check-circle-fill{
    color:#EDA601;
    margin-right: 5px;
}

.bi-award-fill{
    color: #FC7646;
    margin-right: 3px;
}

.bi-cash{
    color: #37BF21;
    margin-right: 3px;
}

.about-tag, .skill{
    font-size: 0.75rem;
    color: #222;
    margin-bottom: -10px;
}

.about-tag{
    font-weight: 600;
}

.about-note{
    font-size: 0.75rem;
    color: #222;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}

h5{
    margin-bottom: -10px;
}

@media (max-width: 768px){
    section{
        width: 90%;
    }

    div.row{
        flex-direction: column;

    }

    .profile{
        width: 90%;
        margin: 20px auto;
        height: 100%;
    }
}

@media (min-width: 769px) and (max-width: 992px){

    div.row{
        flex-direction: row;
        flex-wrap: wrap;
    }

    .profile{
        width: 43%;
        margin: 20px 2% 30px;
        height: 100%;
    }

    .profile:last-child{
        Width: 43%;
        margin: auto;
    }
}
</style>