<template>
    <header ref="header" :class="[background, linkColor]" >
        <div class="brand-logo">
            <router-link to="/" class="orsul-info"> 
                <img src="@/assets/orsul_logo.png" alt="logo" class="orsul-logo">
                <p class="orsul-name" translate="no">O r s u l</p>
            </router-link>
        </div>
        
        <div class="brand-info">
            <i @click="handleClick" class="bi bi-list" id="icon" ref="icon"></i>     
            <nav id="nav" ref="nav">
                <router-link to="/services" class="nav-item" ref="navItem">Services</router-link>
                <router-link to="/how" class="nav-item" ref="navItem">How It Works</router-link>
                <router-link to="/get-started" class="nav-item" ref="navItem">Sign Up/Login</router-link>
                <router-link to="/get-started"><button>Get Started</button></router-link>
            </nav>
        </div>

        <!-- navigation menu for mobile screens -->
        <div class="backdrop" ref="backdrop">
            <div class="brand-info-mobile">
                <i @click="close" class="bi bi-x" id="icon2" ref="icon2"></i>
                <nav id="nav-mobile" ref="navmobile">
                    <router-link to="/services" class="nav-item">Services</router-link>
                    <router-link to="/how" class="nav-item">How It Works</router-link>
                    <router-link to="/get-started" class="nav-item">Sign Up/Login</router-link>
                    <router-link to="/get-started"><button>Get Started</button></router-link>
                </nav>
            </div>
        </div>
  </header>
</template>

<script>
export default {
    name: 'OrsulHeader',
    props: [ 'background', 'linkColor' ],
    methods:{
        handleClick(){
            this.$refs.backdrop.style.display = "flex";
            this.$refs.icon2.style.display = "block";
        },
        close(){
            this.$refs.backdrop.style.display = "none";
            this.$refs.icon2.style.display = "none";
        },
        // comment or uncomment the code block below to add or remove the scroll function
        handleScroll(){
            if (window.scrollY > 20 ) {
                this.$refs.header.classList.remove('transparent'); 
                this.$refs.header.classList.remove('white');

                this.$refs.icon.style.color = "#570897";    
            }else{
                this.$refs.header.classList.add('transparent'); 
                this.$refs.header.classList.add('white');

                this.$refs.icon.style.color = "#fff";
            };
        }
    },
    mounted () {
        var transparentHeader = this.$refs.header.classList.contains('transparent');
        var whiteLink = this.$refs.header.classList.contains('white');
        if (transparentHeader && whiteLink){
            window.addEventListener('scroll', this.handleScroll);
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Baloo+2&display=swap');

body{
    margin:0;
    padding: 0;
}

header{
    width: 100%;
    margin: 0;
    padding: 15px;
    height: 9vh;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 2px 8px 2px rgb(177, 176, 176);
    position: sticky;
    top: 0;
    z-index: 250;
}

.transparent{
    background: transparent;
    box-shadow: none;
}

.white .brand-info #nav a.nav-item{
    color: #fff !important;
}

.purple .brand-info #nav a.nav-item{
    color:#570897;
}

.purple .brand-info #nav a.nav-item:hover, .white .brand-info #nav a.nav-item:hover{
    color:#fff;
    background-color: #570897;
    border-radius: 10px;
}

div.brand-logo{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.brand-info{
    width: 65%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.orsul-info{
    display: flex;
    justify-content: center;
    align-items:center;
    text-decoration: none;
}

.orsul-logo{
    width: 50px;
    height: 50px;
    position: relative;
    margin-left: 15px;
}

.orsul-name{
    font-family: 'Baloo 2', cursive;
    font-weight: 700;
    font-size: 1.4rem;
    color: #570897;
    white-space: pre;
}

#icon {
    display: none;
    margin-right: 10px;
    font-size: 1.7rem;
    font-weight: 700;
    color: #fff;
}

#icon2{
    top: 10px;
    left: 0;
    display: none;
    font-size: 1.7rem;
    font-weight: 700;
    color: #570897;
}

/* mobile view navigation bar */
div.backdrop{
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100vh;
    display: none;
    justify-content: flex-end;
    align-items: center;
    z-index: 300;
}

div.brand-info-mobile{
    width: 75%;
    height: 100%;
    background-color: #fff;
}

#nav-mobile{
    width: 60%;
    margin: auto;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}

#nav-mobile a.nav-item{
    padding: 10px;
    margin-bottom: 15px;
    text-decoration: none;
    color: #570897;
    font-size: 1.1rem;
}

#nav-mobile a.nav-item:hover{
    border-radius: 10px;
    padding: 2px 8px;
}

/* desktop view navigation bar */
#nav{
    width: 75%;
    margin: auto;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}

#nav a.nav-item{
    text-decoration: none;
    font-weight: 300;
    font-size: 0.9rem;
    padding: 3px 6px ;
}

#nav a.nav-item:hover{
    border-radius: 10px;
}

/* get started button */
button{
    outline:none;
    padding: 10px 15px; 
    color: #37BF21;
    background:transparent;
    border: 1px solid #37BF21;
    border-radius: 7px;
}

button:hover{
    background: #37BF21;
    color: white;
}

/* mobile screen media query */
@media (max-width: 768px){
    #icon{
        display: block;
    }

    #nav{
        display: none;
    }

    .orsul-logo{
        width: 35px;
        height: 35px;
    }
}

/* medium screen media query */
@media (min-width: 769px) and (max-width: 992px){
    #icon{
        display: none;
    }

    div.brand-info{
        width: 65%;
    }

    #nav{
        display: flex;
        width: 100%;
    }
}
</style>