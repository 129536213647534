<template>
    <section>
        <h2>Popularly Rendered Services</h2>
        <div class="row">
            <div v-for="card in cards" :key="card.id" class="card">
                <img :src="require(`@/assets/${card.img}`)" :alt="card.name" class="cardimage" loading="lazy">
                <div class="cardinfo">
                    <p class="cardname"> {{ card.name }} </p>
                    <p class="cardcost"><i class="bi bi-tag"></i>Avg. Cost: N{{ card.cost }} </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'OrsulServiceCards',
    data (){
        return{
            cards: [
                { id: 1, name: 'Plumbing', cost: '0.00', img: 'plumbing.jpg' },
                { id: 2, name: 'Carpentry', cost: '0.00', img: 'carpentry.jpg' },
                { id: 3, name: 'Electrical Work', cost: '0.00', img:'electrical.jpg' },
                { id: 4, name: 'Tailoring', cost: '0.00', img: 'tailoring.jpg' },
                { id: 5, name: 'Painting', cost: '0.00', img: 'painting.jpg' },
                { id: 6, name: 'Mechanics', cost: '0.00', img: 'mechanics.jpg' },
                { id: 7, name: 'Cleaning', cost: '0.00', img:'cleaning.jpg' },
                { id: 8, name: 'Delivery', cost: '0.00', img: 'delivery.jpg' }
            ]
        }
    }
}
</script>

<style scoped>
*{
    box-sizing:border-box;
}

section{
    width: 75%;
    margin: auto;
}

h2{
    color: 222;
    margin-left: 10px;
    margin-bottom: -15px;
}

div.row{
    width:100%;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.card{
    width: 23%;
    margin-right: 2%;
    margin-bottom: 30px;
    padding: 0;
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: 1px 1px 2px #ddd;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
}

.card:hover{
    box-shadow: 2px 2px 5px 2px rgb(189, 183, 183);
}

.cardimage{
    width: 100%;
    max-height: 150px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.cardinfo{
    padding: 3px 10px;
}

.cardname{
    font-size: 0.9rem;
    text-align: center;
    color: #222;
    margin-bottom: -10px;
}

.cardcost{
    font-size: 0.8rem;
    text-align: center;
    color: #37BF21;
}

@media (max-width: 768px){
    section{
        width: 90%;
    }

    div.row{
        flex-direction: column;
    }

    .card{
        width: 90%;
        margin: 15px auto;
    }
}

@media (min-width: 769px) and (max-width: 992px){
    div.row{
        flex-direction: row;
        flex-wrap: wrap;
    }

    .card{
        width: 30%;
        margin-right: 2%;
        height: 215px;
    }
}
</style>