<template>
    <OrsulHeader linkColor="purple"/>
    <OrsulServices/>
    <OrsulFooter />
</template>

<script>
import OrsulHeader from '@/components/OrsulHeader.vue'
import OrsulServices from '@/components/OrsulServices'
import OrsulFooter from '@/components/OrsulFooter.vue'

export default ({
  name: 'Services',
  components: {
    OrsulHeader, OrsulServices, OrsulFooter
  },
  mounted () {
        document.title = 'Orsul Services'
    }
})
</script>

<style scoped>
*{
  box-sizing: border-box;
}
</style>

